<template>
  <div class="featured-conditions" data-test-id="featured-conditions">
    <div
      v-for="disorder in disorders"
      :key="`featured-disorder-${disorder.id}`"
      class="featured-conditions__condition-wrapper"
    >
      <router-link
        :to="`conditions/${disorder.slug}`"
        class="featured-conditions__condition"
        data-test-id="featured-condition-link"
      >{{ disorder.name }}</router-link>
    </div>
  </div>
</template>

<script>
import { FEATURED_DISORDERS } from '@/graphql/queries/disorder-queries';

export default {
  name: 'ConditionSelect',
  data() {
    return {
      disorders: [],
    };
  },
  methods: {
    randomSetOfDisorders(disorders) {
      const sizeOfSet = 7;
      const shuffledDisorders = [...disorders].sort(() => 0.5 - Math.random());
      return shuffledDisorders.slice(0, sizeOfSet);
    },
  },
  apollo: {
    featuredDisorders: {
      query: FEATURED_DISORDERS,
      result({ data }) {
        this.disorders = this.randomSetOfDisorders(data.featuredDisorders);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_featured-conditions';
</style>
