<template>
  <div class="condition-search-section" data-test-id="condition-search-section">
    <h2 class="condition-search-section__heading">{{ heading }}</h2>
    <p class="condition-search-section__subheading">{{ subheading }}</p>
    <condition-select
      class="condition-search-section__condition-select"
      :click-handler-for-options="goToConditionPage"
    />
    <p class="condition-search-section__featured-conditions-text">{{ featuredConditionText }}</p>
    <featured-conditions />
  </div>
</template>

<script>
import ConditionSelect from '@/components/Disorder/ConditionSelect.vue';
import FeaturedConditions from '@/components/Disorder/FeaturedConditions.vue';

export default {
  name: 'ConditionSearchSection',
  components: { ConditionSelect, FeaturedConditions },
  props: {
    heading: {
      type: String,
      default: '',
    },
    subheading: {
      type: String,
      default: '',
    },
    featuredConditionText: {
      type: String,
      default: '',
    },
  },
  methods: {
    goToConditionPage(event, disorder) {
      this.$router.push(`conditions/${disorder.slug}`);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_condition-search-section';
</style>
